 
<template>
  <v-navigation-drawer
    v-model="drawer"
    absolute
  >
    <v-list-item
      class="pt-5 pb-5"
      v-for="item in items"
      :key="item.title"
      :title="item.title"
      :to="item.to"
  ></v-list-item>
  </v-navigation-drawer>
</template>

<script>
export default {
    name: 'navigationDrawer',
    data: () => ({
      drawer: true,
      items: [
        {
          "title": 'Dashboard',
          "value": 'dashboard',
          "to" : 'dashboard'
        }
      ],
    }),
    mounted() {
      const stringData = localStorage.roles;
      if (stringData !== undefined) {
        const rolesArr = JSON.parse(stringData);
        Object.keys(rolesArr).forEach(key => {
          if(rolesArr[key] === 1) {
            this.items.push({
              "title":this.translateTitle(key),
              "value": key,
              "to" : key
            });
          }
        }); 
      }
    },
    methods: {
      toggleDrawer() {
        this.drawer = !this.drawer;
      },
      translateTitle(value) {
        switch (value) {
          case 'mag_prod':
            return 'Magazyn Produkcyjny';
          case 'mag_tow':
            return 'Magazyn Towarowy';
          default:
            ''
        }
      } 
    },
}
</script>