<template>
  <v-app>
    <v-app-bar
      v-if="isLogged"
      color="red"
    >
    <v-app-bar-nav-icon variant="text" @click.stop="openDrawer"></v-app-bar-nav-icon>
       Panel BKF
    <v-spacer></v-spacer>
      {{ this.user }}
      <v-btn
        class="ma-2"
        icon
        @click="storeLogout"
      >
        <v-icon>
          mdi-logout
        </v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <navigationDrawer
        v-if="isLogged"
        ref="menu"
      />
      <router-view 
        ref="view"
      >
      </router-view>
    </v-main>
  </v-app>
</template>

<script >
import navigationDrawer from './components/navigationDrawer.vue';
import { mapGetters, mapActions} from 'vuex';

export default {
  name: 'PanelBkf',
  components: {
    navigationDrawer,
  },
 methods: {
    openDrawer() {
      this.$refs.menu.toggleDrawer();
    },
    ...mapActions({
      storeLogout: 'logout',
    })
  },
  computed: {
    ...mapGetters({
      isLogged: 'isLoggedIn',
      user: 'getUsername',
      roles: 'getRoles',
    }),
  },
  watch: {
    async isLogged(newValue, oldValue) {
      if (newValue === false && oldValue === true) {
        this.$router.push('/');
      }
    }
  },
}
</script>
