<template>
  <v-card
    tile
    outlined
    shaped
    flat
    align="center"
    class="mt-10"
  >
    <v-card-title>
      PANEL BKF
    </v-card-title>
    <v-card-subtitle>
      Wybierz moduł do pracy
    </v-card-subtitle> 
  </v-card>
</template>
<script>

export default {
  name: 'DashboardView',
  data: () => ({
  }),
}
</script>