import axios from "axios";

export const auth = {
  state: () => ({
    status: '',
    access_token: localStorage.getItem('access_token') || null,
    refresh_token: localStorage.getItem('refresh_token') || null,
    user: localStorage.getItem('username') || null,
    roles: JSON.parse(localStorage.getItem('roles')) || null,
  }),

  actions: {
    async login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request');
        const clientId = process.env.VUE_APP_API_CLIENT_ID;
        const clientSecret = process.env.VUE_APP_API_CLIENT_SECRET;
        const postData = new FormData();
        postData.append('grant_type', 'password');
        postData.append('client_id', clientId);
        postData.append('client_secret', clientSecret);
        postData.append('username', user.username);
        postData.append('password', user.password);
        
        axios.post('/token', postData, { 'Content-Type': 'multipart/form-data' })
          .then((resp) => {
            const accessToken = resp.data.access_token;
            const refreshToken = resp.data.refresh_token;
            localStorage.setItem('access_token', accessToken);
            localStorage.setItem('refresh_token', refreshToken);
            localStorage.setItem('username', user.username);
            axios.defaults.headers.common.Authorization = accessToken;
            commit('auth_success', {
              accessToken, refreshToken, user: user.username,
            });
            resolve(resp);
          })
          .catch((err) => {
            commit('auth_error');
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('username');
            reject(err);
          });
      });
    },
    async getRoles({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request');
        const postData = {'username': user.username};
        axios.post('api/panel/get-roles', postData, { 'Content-Type': 'application/json' })
          .then((resp) => {
            const roles = resp.data.roles;
            localStorage.setItem('roles', JSON.stringify(roles));
            commit('roles_success', {
              roles
            });
            resolve(resp);
          })
          .catch((err) => {
            commit('auth_error');
            localStorage.removeItem('roles');
            reject(err);
          });
      });
    },
    async logout({commit}) {
      return new Promise((resolve) => {
        commit('logout');
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        delete axios.defaults.headers.common.Authorization;
        resolve();
      });
    },
    async refresh({ commit }) {
      const refreshToken = localStorage.getItem('refresh_token');
      return new Promise((resolve, reject) => {
        commit('auth_request');
        const clientId = process.env.VUE_APP_API_CLIENT_ID;
        const clientSecret = process.env.VUE_APP_API_CLIENT_SECRET;
  
        const postData = new FormData();
  
        postData.append('grant_type', 'refresh_token');
        postData.append('client_id', clientId);
        postData.append('client_secret', clientSecret);
        postData.append('refresh_token', refreshToken);
  
        axios.post('/token', postData, { 'Content-Type': 'multipart/form-data' })
          .then((resp) => {
            const accessToken = resp.data.access_token;
            const refreshToken = resp.data.refresh_token;
            localStorage.setItem('access_token', accessToken);
            localStorage.setItem('refresh_token', refreshToken);
            axios.defaults.headers.common.Authorization = accessToken;
            commit('auth_success_refresh', { accessToken, refreshToken });
            resolve(resp);
          })
          .catch((err) => {
            commit('auth_error');
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            reject(err);
          });
      });
    },
  },

  mutations: {
    auth_request(state) {
      state.status = 'loading';
    },
    auth_success(state, {
      accessToken, refreshToken, user,
    }) {
      state.status = 'success';
      state.access_token = accessToken;
      state.refresh_token = refreshToken;
      state.user = user;
    },
    auth_error(state) {
      state.status = 'error';
      state.access_token = null;
      state.refresh_token = null;
      state.user = null;
      state.roles = null;
    },
    logout(state) {
      state.status = '';
      state.access_token = null;
      state.refresh_token = null;
      state.roles = null;
      state.user = null;
    },
    auth_success_refresh(state, {
      accessToken, refreshToken,
    }) {
      state.status = 'success';
      state.access_token = accessToken;
      state.refresh_token = refreshToken;
    },
    roles_success(state, {
      roles
    }) {
      state.roles = roles;
    },
  },

  getters: {
    isLoggedIn: (state) => state.access_token !== null,
    getRoles: (state) => state.roles,
    getUsername: (state) => state.user,
  }
}