<template>
  <v-container
    fluid
    class="fill-height"

  >
 <v-row align="center"
      justify="center">
        <v-card
          tile
          class="mt-15"
          width = 600
          outlined = false
          rounded = false
        >
          <v-toolbar
            dark
            color="red"
          >
            <v-toolbar-title>Panel BKF</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text >
            <v-form
              ref="form"
            >
              <v-alert
                v-if="error"
                type="error"
              >
                Niepoprawny użytkownik lub hasło
              </v-alert>
              <v-text-field
                v-model="email"
                prepend-icon="mdi-account"
                label="Login"
                type="text"
                ref='login'
                @keyup.enter="logIn"
              />
              <v-text-field
                id="password"
                v-model="password"
                prepend-icon="mdi-lock"
                label="Hasło"
                type="password"
                ref='password'
                @keyup.enter="logIn"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>

            <v-btn
              type="submit"
              tile
              dark
              :loading="loading"
              @click="logIn"
            >
              Zaloguj
            </v-btn>
          </v-card-actions>
        </v-card>
</v-row>

  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      email: null,
      password: null,
      loading: false,
      error: false,
    };
  },
  computed: {
    ...mapGetters({
      isLogged: 'isLoggedIn',
    }),
  },
  mounted() {
    this.$refs.login.focus();
    if(this.isLogged) {
      this.$router.push("/dashboard");
    }
  },
  methods: {
    logIn() {
      this.loading = true;
      this.error = false;
      this.$store.dispatch(
        'login',
        { username: this.email, password: this.password },
      ).then(
        () => {
          this.getRoles(this.email);
          //this.$router.push('/dashboard');
        },
      ).finally(() => {
        this.loading = false;
      });
    },
    getRoles(username){
      this.$store.dispatch(
        'getRoles',
        { username},
      ).then(
        () => {
          this.$router.push('/dashboard');
        },
      ).finally(() => {
        this.loading = false;
      });
    }
  },
};
</script>
<style >
input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 2s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}
</style>