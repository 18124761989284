<template>
  <v-card class="mx-auto pl-5 pr-5 pt-5" v-if="!loading">
    <v-card-title>
      <v-row class="mt-1" no-gutters>
        <v-col cols="6">
          Lista wózków produkcyjnych
        </v-col>
        <v-col cols="6" align="right">
          <h6 class="lastSynchro">
            ostatnia synchronizacja<br />
            {{ lastSynchro }}
          </h6>
        </v-col>
      </v-row>
    </v-card-title>
    <v-list lines="three" class="cartsList" v-if="carts.length">
      <v-list-item
        v-for="item in carts"
        :key="item.deviceSerialNumberIndex + item.actionStageIndex"
        :title="item.deviceSerialNumberIndex"
        :subtitle="item.actionStageIndex"
        outlined
        :style="{ backgroundColor: item.color }"
      >
        <template v-slot:prepend>
          <v-row :class="`${item.status !== 'new' ? 'negative-up' : 'mt-5'}`">
            <v-icon color="grey">{{ setCartIcon(item) }}</v-icon>
          </v-row>
          <v-row v-if="item.status !== 'new'">
            <v-icon color="grey" class="mt-5">mdi-arrow-up-down</v-icon>
          </v-row>
        </template>
        <template v-slot:title>
          <span v-if="item.cartType === 'std'" class="pl-10">
            {{ item.deviceSerialNumberIndex }}
          </span>
          <span v-else class="pl-10">
            {{ item.deviceSerialNumberIndex }} <small>{{ item.cartDocumentIndex }}</small>
          </span>
        </template>
        <template v-slot:subtitle>
          <span class="pl-10">{{ item.actionStageIndex }}</span>
        </template>
      </v-list-item>
      <v-divider />
    </v-list>
  </v-card>
  <div class="text-center mt-15 pt-15" v-else>
    <v-progress-circular indeterminate color="red" :size="100" />
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'MagazynProdukcyjny',
  data: () => ({
    carts: [],
    loading: false,
    lastSynchro: null,
    interval: null,
    audio: new Audio('http://soundbible.com/mp3/Air Plane Ding-SoundBible.com-496729130.mp3'),
  }),
  mounted() {
    window.focus();
    this.getCarts();
    this.interval = setInterval(() => {
      this.getCarts();
    }, 60000);
  },
  beforeUnmount() {
    this.carts = [];
    clearInterval(this.interval);
    this.audio.src = null;
    this.audio = null;
  },
  methods: {
    getCarts() {
      this.loading = true;
      this.axios.get('/api/cart-prod/carts/NEW/PROD').then((response) => {
        if (response.data) {
          if (this.carts.length !== response.data.carts_list.length) {
            this.playSound();
          }
          this.carts = response.data.carts_list;
        }
        this.loading = false;
        this.lastSynchro = this.getCurrentTime();
      });
    },
    getCurrentTime() {
      return moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    },
    setCartIcon(item) {
      switch (item.group_main_name) {
        case 'Myjnia':
          return 'mdi-car-wash';
        case 'Rozmieniarka':
          return 'mdi-hand-coin-outline';
        case 'Trzepaczka':
          return 'mdi-gradient';
        case 'Kamper':
          return 'mdi-rv-truck';
        case 'Odkurzacz':
          return 'mdi-robot-mower-outline';
        case 'Myjka':
          return 'mdi-water-outline';
        case 'Portal':
          return 'mdi-arrow-up-bold-box-outline';
        case 'WEW':
          return 'mdi-download-outline';
        default:
          return 'mdi-cart';
      }
    },
    playSound() {
      if (this.audio) {
        this.audio.currentTime = 0;
        this.audio.play();
      }
    },
  },
};
</script>

<style>
.cartsList div.v-list-item-title {
  font-size: 2.0rem !important;
}
.cartsList div.v-list-item-subtitle {
  font-size: 1.5rem !important;
  padding-top: 10px;
  padding-bottom: 5px;
}
.cartsList div.v-list-item {
  border-bottom: 1px solid #ccc;
}
.lastSynchro {
  line-height: 20px;
}
.negative-up {
  margin-top: -30px !important;
}
</style>