import { createWebHistory, createRouter } from "vue-router";
import store from '../store';
import loginForm from "../views/loginForm.vue";
import dashboard from "../views/dashboard.vue";
import magProd from "../views/mag_prod.vue";
import magTow from "../views/mag_tow.vue";

const routes = [
  {
    path: "/mag_prod",
    name: "mag_prod",
    component: magProd,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mag_tow",
    name: "mag_tow",
    component: magTow,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/",
    name: "loginForm",
    component: loginForm,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: dashboard,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters['isLoggedIn']) {
      next();
      return;
    }
    next('/');
  } else {
    next();
  }
});


export default router;