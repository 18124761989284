import { createApp } from 'vue'
import router from './router' 
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import store from './store/index';
import VueAxios from 'vue-axios'
import axios from './plugins/axios';

loadFonts()

createApp(App)
  .use(vuetify)
  .use(router)
  .use(VueAxios, axios)
  .use(store).mount('#app')
