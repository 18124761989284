import store from '../store';
import axios from 'axios'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = 'https://127.0.0.1:3500';
axios.defaults.baseURL = 'https://api-bkf.bkf.pl';

const config = {
  headers: {},
};
// eslint-disable-next-line no-underscore-dangle
const _axios = axios.create(config);
_axios.interceptors.request.use(
  // eslint-disable-next-line no-shadow
  (config) => {
    const accessToken = store.state.auth.access_token || localStorage.getItem('access_token') || null;
    if (accessToken !== null) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
  ,
);
// Add a response interceptor
_axios.interceptors.response.use(
  (response) => response,
  // eslint-disable-next-line consistent-return
  async (error) => {
    // Do something with response error
    if (error.response.status === 401 && !error.config.url.includes('token')) {
      await store.dispatch('refresh');
      const newToken = store.getters['auth/access_token'];
      // eslint-disable-next-line no-param-reassign
      error.config.headers.Authorization = `Bearer ${newToken}`;
      return new _axios(error.config);
    }

    return Promise.reject(error);
  },
);

export default _axios;
